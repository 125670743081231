import React from 'react';
import './ServicesSection.css';

function ServicesSection() {
  return (
    <section className="services">
      <h2>Services</h2>
      <p>Providing you with personalized fitness solutions to achieve your goals.</p>
      
      <div className="service-cards">
        <div className="service-card">
          <i className="icon">🏋️</i>
          <h3>Personal Training</h3>
          <p>One-on-one sessions tailored to your specific needs and goals. Whether you're new to fitness or looking to break through plateaus, I'll guide you every step of the way.</p>
          <ul>
            <li>Customized workout plans</li>
            <li>Nutrition guidance</li>
            <li>Form correction and technique improvement</li>
            <li>Motivation and accountability</li>
          </ul>
        </div>
        
        <div className="service-card">
          <i className="icon">💻</i>
          <h3>Online Coaching</h3>
          <p>Flexible, remote coaching for those who prefer to train on their own schedule. Get expert guidance and support, no matter where you are.</p>
          <ul>
            <li>Personalized training programs</li>
            <li>Nutrition plans and advice</li>
            <li>Regular check-ins and progress tracking</li>
            <li>24/7 support via messaging</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default ServicesSection;
