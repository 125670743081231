import React from 'react';
import './ContactSection.css';

function ContactSection() {
  return (
    <section className="contact-section">
      <h2>Contact</h2>
      <div className="contact-container">
        <div className="contact-info">
          <h3>Get in Touch</h3>
          <p>Have a question or want to book a session? Feel free to reach out!</p>
          <ul>
            <li>
              <i className="fas fa-envelope"></i>
              <a href="mailto:live10ninety@gmail.com">live10ninety@gmail.com</a>
            </li>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              <span>New York, NY, USA</span>
            </li>
          </ul>
          <div className="social-links">
            <a href="#" className="social-icon"><i className="fab fa-facebook-f"></i></a>
            <a href="#" className="social-icon"><i className="fab fa-instagram"></i></a>
            <a href="#" className="social-icon"><i className="fab fa-twitter"></i></a>
          </div>
        </div>
        <form className="contact-form">
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea name="message" placeholder="Your Message" required></textarea>
          <button type="submit">Send Message</button>
        </form>
      </div>
    </section>
  );
}

export default ContactSection;
