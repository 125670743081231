import React from 'react';
import './BlogSection.css';

function BlogSection() {
  const brandmark = require('./images/10Ninety_Brandmark_192.png');

  return (
    <section className="blog-section" style={{'--brandmark-image': `url(${brandmark})`}}>
      <div className="blog-container">
        <div className="blog-image">
          {/* Placeholder for the image */}
          <div className="image-placeholder"></div>
        </div>
        <div className="blog-content">
          <h2>Blog</h2>
          <h3>I've stopped tracking my workouts!</h3>
          <p>The past few weeks I've stopped tracking my gym sessions on my watch! Why?? There's a few reasons:</p>
          <a href="#" className="read-more">Read More</a>
        </div>
      </div>
    </section>
  );
}

export default BlogSection;
