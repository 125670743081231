import React from 'react';
import './PlansSection.css';

function PlansSection() {
  return (
    <section className="plans-section">
      <h2>Plans</h2>
      <div className="plans-container">
        <div className="plan">
          <h3>1 to 1 Personal Training</h3>
          <p className="price">$75 <span>per session</span></p>
          <ul>
            <li>Personalised workout plans</li>
            <li>Nutrition advice</li>
            <li>1 hour sessions</li>
            <li>Flexible scheduling</li>
          </ul>
          <a href="#contact" className="plan-button">Get Started</a>
        </div>
        <div className="plan">
          <h3>Online Coaching starting from</h3>
          <p className="price">$50 <span>per month</span></p>
          <ul>
            <li>Customized training plans</li>
            <li>Detailed nutrition plans</li>
            <li>24/7 coach support</li>
            <li>Progress tracking</li>
          </ul>
          <a href="#contact" className="plan-button">Start Coaching</a>
        </div>
      </div>
    </section>
  );
}

export default PlansSection;
