import React from 'react';
import './App.css';
import AboutSection from './AboutSection';
import BlogSection from './BlogSection';
import PlansSection from './PlansSection';
import ContactSection from './ContactSection';
import ServicesSection from './ServicesSection'; // Add this line

function App() {
  return (
    <div className="App">
      <header className="header">
        <div className="logo">
          <img src="/Horiz_10Ninety_logo.png" alt="10Ninety logo" className="header-logo" />
        </div>
        <nav>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#blog">Blog</a></li>
            <li><a href="#plans">Plans</a></li>
            <li><a href="#questionnaire">Questionnaire</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </header>

      <main className="main-content">
        <div className="text-content">
          <h1>Eric Zalewski</h1>
          <p>Providing you with the tools to work towards a happier, healthier, stronger you.</p>
          <button className="contact-button">Contact</button>
        </div>
        <div className="image-content">
          <img src="/10Ninety-Brandmark.png" alt="10ninety logo" className="main-content-logo" />
        </div>
      </main>

      <AboutSection />
      <ServicesSection /> {/* Add this line */}
      <BlogSection />
      <PlansSection />
      <ContactSection />
    </div>
  );
}

export default App;
